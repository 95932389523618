/* If you need to add @import statements, do so up here */

@import 'jit-refresh.css'; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

a.link {
  @apply text-blue-600 hover:text-blue-800;
}

.tag {
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  border-radius: 4px;
  white-space: nowrap;
  color: white;
}

.tag.ruby-on-rails {
  background-color: #cc0000;
}

.tag.ruby-on-rails {
  background-color: #cc0000;
}

.tag.bulma-css {
  background-color: #00d1b2;
}

.tag.postgresql {
  background-color: #336791;
}

.tag.stimulus-js,
.tag.stimulusreflex {
  background-color: #6b6b6b;
}

.tag.react-native {
  background-color: #61dafb;
}

.tag.apple-tv {
  background-color: black;
}

.tag.redis {
  background-color: #dc382d;
}

.tag.react {
  background-color: #61dafb;
}

.tag.bootstrap {
  background-color: #7952b3;
}
